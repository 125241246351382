import {MaterialReactTable, type MRT_ColumnDef, useMaterialReactTable} from "material-react-table";
import {useEffect, useMemo} from "react";
import {InstructorProgramCourseSelection} from "@/components/Providers/InstructorProgramsProvider.tsx";
import moment from "moment";
import {useHistory} from "react-router-dom";

type Props = {
    data : InstructorProgramCourseSelection[];
    programId : string;
};

const InstructorCourseList = ({data, programId}: Props) => {
    const history = useHistory();

    const displayCoursesColumns = useMemo<MRT_ColumnDef<InstructorProgramCourseSelection>[]>(
        () => [
            {
                accessorKey: 'registrations',
                header: 'Registrations',
                size: 10,
                Cell: ({row}) => {
                    return <a
                        onClick={() => {
                            history.push(`/instructor/course/${programId}/${row.original.id}`);
                        }}
                        href={'#'}
                    >
                        View Registrations
                    </a>
                }
            },
            {
                accessorKey: 'subject',
                accessorFn: (course) => course.course?.subject,
                header: 'Subject',
                size: 10,
            },
            {
                accessorKey: 'courseNumber',
                accessorFn: (course) => course.course?.courseNumber,
                header: 'Course #',
                size: 10,
            },
            {
                accessorKey: 'sectionNumber',
                header: 'Section #',
                size: 10,
            },
            {
                accessorKey: 'name',
                header: 'Description',
                size: 10,
                Cell: ({row}) => {
                    return <a
                        onClick={() => {
                            history.push(`/instructor/course/${programId}/${row.original.id}`);
                        }}
                        href={'#'}
                    >{row.original.course.courseName}</a>
                },
            },
            {
                accessorKey: 'term',
                accessorFn: (_data) => {
                    return 'test'
                },
                header: 'Term',
                size: 10,
            },
            {
                accessorKey: 'dates',
                accessorFn: (course) => {
                    return course.meetingDateStart
                },
                Cell: ({row}) => {
                    const startingDate = moment(row.original.meetingDateStart);
                    const endingDate = moment(row.original.meetingDateEnd);

                    if (!startingDate.isValid() || !endingDate.isValid()) {
                        return '';
                    }

                    return `${startingDate.format('l')} - ${endingDate.format('l')}`
                },
                header: 'Dates',
                sortingFn: 'datetime',
                size: 10,
            },
            {
                accessorKey: 'meetingDays',
                accessorFn: (course) => course.meetingDaysOfWeek?.replace('\r', ', '),
                header: 'Days',
                size: 10,
            },
            {
                accessorKey: 'meetingTime',
                accessorFn: (course) => {
                    const date = moment(course.meetingTimeStart, 'hh:mm:ss');

                    if (!date.isValid()) {
                        return "";
                    }

                    return date.format('LT')
                },
                header: 'Time',
                size: 10,
            },
        ],
        [],
    );

    const displayCoursesTable = useMaterialReactTable({
        columns: displayCoursesColumns,
        data: data,
        enableColumnActions: false,
        enableColumnFilters: false,
        enableDensityToggle: false,
        enableFilterMatchHighlighting: false,
        enableFullScreenToggle: false,
        enableGlobalFilter: true,
        enableHiding: false,
        enablePagination: true,
        icons: {
            SortIcon: () => null,
        },
        initialState: {
            columnVisibility: {
                courseSelectionUUID: false,
                statusOriginal: false
            },
            pagination: {pageSize: 40, pageIndex: 0},
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#f2e4ff',
                fontWeight: '700',
                fontSize: '14px',
                padding: '12px',
                '& span': {
                    backgroundColor: 'red',
                    display: 'none',
                }
            },
        },
        muiTableBodyCellProps: {
            sx: {
                fontWeight: 'normal',
                fontSize: '14px',
                padding: '12px',
            },
        },
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 990) { // Adjust breakpoint as needed
                displayCoursesTable.setColumnVisibility((prev) => ({
                    ...prev,
                    registrations: true,
                    name: true,
                    dates: true,

                    subject: false,
                    courseNumber: false,
                    sectionNumber: false,
                    term: false,
                    meetingDays: false,
                    meetingTime: false,
                }));
            } else {
                displayCoursesTable.setColumnVisibility({
                    registrations: true,
                    name: true,
                    dates: true,

                    subject: true,
                    courseNumber: true,
                    sectionNumber: true,
                    term: true,
                    meetingDays: true,
                    meetingTime: true,
                });
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return  <MaterialReactTable table={displayCoursesTable}/>;
}

export default InstructorCourseList;
