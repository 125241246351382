import React from 'react';
import NavHeader from "../components/Navigation/NavHeader";
import {Col, Container, Row} from "react-bootstrap";
import {Program, ProgramsProviderContext} from "../components/Providers/ProgramsProvider";
import ProgramSelection from "../components/Program/ProgramSelection";
import ProgramCourses from "../components/Program/ProgramCourses";
import ProgramSelectField from "../components/Forms/Fields/ProgramSelectField";
import SystemAlert from "../components/SystemAlert";
import CircularLoadingIndicator from "../components/CircularLoadingIndicator";
import SystemNoticeModal from "../components/Modal/SystemNoticeModal";
import SurveyForm from "../components/Forms/SurveyForm";

const Programs = () => {
    const [programsState,,clearRegistrationError] = React.useContext(ProgramsProviderContext);
    const [activeProgramName, setActiveProgramName] = React.useState<string | null>(null);
    const [needsSurvey, setNeedsSurvey] = React.useState<boolean>(false);
    const activeProgram = activeProgramName ? programsState.programs.find(
        (program) => program.name === activeProgramName
    ) : undefined;

    React.useEffect(() => {
        setNeedsSurvey(activeProgramName !== null && activeProgram?.requiresSurvey === true && activeProgram?.finishedSurvey === false);
    },[activeProgramName, activeProgram])

    const openPrograms = programsState.programs.filter((program : Program) => program.open);

    let availablePrograms : Array<string> = openPrograms.map((program) => {
       return program.name;
    });

    return (
        <>
            <NavHeader showNav={true}/>
            <Container>
                <Row className={`${(activeProgramName ? 'my-3' : '')} program-selected`}>
                    {activeProgramName && (
                        <Col sm={6} className="d-flex flex-row justify-content-start">
                            <div className="mt-2 mr-2">Program Selected:</div>
                            <ProgramSelectField
                                availablePrograms={availablePrograms}
                                setActiveProgram={setActiveProgramName}
                                defaultProgram={activeProgramName}
                            />
                        </Col>
                    )}
                </Row>
            </Container>
            <Container className="pb-4">
                {programsState.loading ? (
                    <CircularLoadingIndicator/>
                ) : (
                    <>
                        {!activeProgramName || !activeProgram ? (
                            <ProgramSelection
                                availablePrograms={availablePrograms}
                                loading={programsState.loading}
                                setActiveProgram={setActiveProgramName}
                            />
                        ) : (needsSurvey ? <SurveyForm
                            setNeedsFinishSurvey={setNeedsSurvey}
                            program={activeProgram}
                        /> :(
                            <ProgramCourses
                                programName={activeProgram.name}
                                setActiveProgram={setActiveProgramName}
                                courses={activeProgram.courses}
                            />
                        ))}
                    </>
                )}
            </Container>
            <SystemAlert
                show={programsState.showAlert}
                alertText={programsState.message}
                clearSystemAlert={clearRegistrationError}
                variant={programsState.alertVariant}
                isFixed={true}
            />
            <SystemNoticeModal/>
        </>
    );
};

export default Programs;
