import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';

type Props = {
    feeCategory: string;
    setFeeCategory: (feeCategory: string) => void;
    feeCategories: string[];
};

 const CategorySelect = ({feeCategory, setFeeCategory, feeCategories} : Props) : React.ReactElement => {
    const handleChange = (event: SelectChangeEvent) => {
        setFeeCategory(event.target.value);
    };

     return (
        <FormControl
            sx={{
                width: '100%',
            }}
        >
            <Select
                id="sort-by-category"
                value={feeCategory}
                displayEmpty
                onChange={handleChange}
            >
                <MenuItem value={""}>All</MenuItem>

                {feeCategories.map(feeCategory => {
                    return <MenuItem
                        key={`category-select-${feeCategory}`}
                        value={feeCategory}
                    >
                        {feeCategory}
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

export default CategorySelect;
