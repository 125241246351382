import {BrowserRouter, Route, Switch} from 'react-router-dom';
import JWTProvider, {JWTInstructorProvider} from "./components/Providers/JWTProvider";
import NoticeProvider from "./components/Providers/NoticeProvider";
import RegisterCommunityMember from './pages/RegisterCommunityMember';
import Routes from "./Routes";
import InstructorSignIn from "./pages/instructor/InstructorSignIn";
import InstructorProgram from "./pages/instructor/InstructorProgram";
import InstructorProgramsProvider from "./components/Providers/InstructorProgramsProvider";
import ActivityDetailView from "./pages/instructor/ActivityDetailView";
import InstructorProgramList from "./pages/instructor/InstructorProgramList";
import {ScheduledMaintenance} from "./pages/ScheduledMaintenance";
import FeeProposalsPage from "@/pages/instructor/FeeProposalsPage.tsx";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import InstructorFeeProposalsProvider from "@/components/Providers/InstructorFeeProposalsProvider.tsx";
import HomePage from "@/pages/HomePage.tsx";
import {createTheme, ThemeProvider} from "@mui/material";


let theme = createTheme({
    palette: {
        secondary: {
            main: 'rgb(99, 29, 121)',
        },
    },
});

const Guard = () => (
    <NoticeProvider>
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route path="/instructor">
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <JWTInstructorProvider>
                        <Route path="/instructor/signin" exact component={InstructorSignIn}/>
                        <InstructorProgramsProvider>
                            <InstructorFeeProposalsProvider>
                                <Route path="/instructor" exact component={InstructorProgramList}/>
                                <Route path="/instructor/program/:programId" exact component={InstructorProgram}/>
                                <Route path="/instructor/course/:programId/:courseId" exact component={ActivityDetailView}/>
                                <Route path="/instructor/fee-proposals" exact component={FeeProposalsPage}/>
                            </InstructorFeeProposalsProvider>
                        </InstructorProgramsProvider>
                    </JWTInstructorProvider>
                </LocalizationProvider>
            </Route>
            <JWTProvider>
                <Routes/>
            </JWTProvider>
        </Switch>
    </NoticeProvider>
);

const App = () => {
    return (<>
        <ThemeProvider theme={theme}>
            <ScheduledMaintenance>
                <BrowserRouter>
                    <Switch>
                        <Route path="/register-community-member" exact component={RegisterCommunityMember} />
                        <Route path="/" component={Guard} />
                    </Switch>
                </BrowserRouter>
            </ScheduledMaintenance>
        </ThemeProvider>
    </>);
}

export default App;
