import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {Fee} from "@/types/Fee.ts";
import {AssessedFee} from "@/types/AssessedFee.ts";
import useGetFees from "@/hooks/useGetFees.ts";
import useGetStudentFees from "@/hooks/useGetStudentFees.ts";
import {jwtContext} from "@/components/Providers/JWTProvider.tsx";
import useGetPurchaseHistory from "@/hooks/useGetPurchaseHistory.ts";

export type PurchaseHistory = {
    fees: Record<string, number>;
    partialStudentFeeIdsAndAmountRemaining: Record<string, number>;
}
export type FeesState = {
    assessedFees: AssessedFee[],
    purchasedFees: AssessedFee[],
    allAssessedFees: AssessedFee[],
    optionalFees: Fee[],
    refreshFees: () => Promise<void>,
    purchaseHistory: PurchaseHistory
    refreshPurchaseHistory: () => Promise<void>,
    doGetStudentFees: () => Promise<void>,
}

type Props = {
    children ?: ReactNode;
};

export const FeesProviderContext = React.createContext<FeesState>(
    {
        allAssessedFees: [],
        assessedFees: [],
        optionalFees: [],
        purchasedFees: [],
        refreshFees: async () => {},
        purchaseHistory: {
            partialStudentFeeIdsAndAmountRemaining: {},
            fees: {},
        },
        refreshPurchaseHistory: async () => {},
        doGetStudentFees: async () => {},
    }
);

const FeeProvider : React.FC<Props> = ({children} : Props) => {
    const user = useContext(jwtContext);

    const [optionalFees, setOptionalFees] = useState<Fee[]>([]);
    const [assessedFees, setAssessedFees] = useState<AssessedFee[]>([]);
    const [purchasedFees, setPurchasedFees] = useState<AssessedFee[]>([]);
    const [allAssessedFees, setAllAssessedFees] = useState<AssessedFee[]>([]);
    const [purchaseHistory, setPurchaseHistory] = useState<PurchaseHistory>({
        partialStudentFeeIdsAndAmountRemaining: {},
        fees: {},
    });

    const getFees = useGetFees();
    const getStudentFees = useGetStudentFees();
    const getPurchaseHistory = useGetPurchaseHistory();

    const refreshFees = async () => {
        await doGetStudentFees();
        await doGetFees();
        await refreshPurchaseHistory();
    }

    const doGetFees = async () => {
        const response = await getFees();

        if (response && response.ok) {
            const {fees} = await response.json() as { fees: Fee[] };
            setOptionalFees(fees);
        }
    }

    const refreshPurchaseHistory = async () => {
        const response = await getPurchaseHistory();

        if (response && response.ok) {
            const purchaseHistory = await response.json() as PurchaseHistory;
            setPurchaseHistory(purchaseHistory);
        }
    }

    const doGetStudentFees = async () => {
        const response = await getStudentFees();

        if (response && response.ok) {
            const {studentFees} = await response.json() as { studentFees: AssessedFee[] };
            setAssessedFees(studentFees.filter((f) => !f.selfAssessed && f.status != 'paid'));
            setPurchasedFees(studentFees.filter((f) => ['paid', 'partial'].includes(f.status)));
            setAllAssessedFees(studentFees);
        }
    }

    useEffect(() => {
        if (user?.userType === 'student') {
            void doGetStudentFees();
        }

        if (user?.userType === 'student') {
            void doGetFees();
        }

        if (user?.userType === 'student') {
            void refreshPurchaseHistory();
        }
    }, []);

    const value : FeesState = {
        allAssessedFees,
        optionalFees,
        assessedFees,
        purchasedFees,
        refreshFees,
        purchaseHistory,
        refreshPurchaseHistory,
        doGetStudentFees
    }

    return (
        <FeesProviderContext.Provider value={value}>
            {children}
        </FeesProviderContext.Provider>
    );
};

export const useFeeProvider = () : FeesState => {
    return useContext(FeesProviderContext);
};

export default FeeProvider;

