import {Dispatch, SetStateAction, useContext, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputAdornment
} from "@mui/material";
import FeeQuestionsForm from "@/components/Forms/FeeQuestionsForm.tsx";
import {RhfCheckbox, RhfTextField} from "mui-rhf-integration";
import MenuItem from "@mui/material/MenuItem";
import {Employee} from "@/types/Employee.ts";
import RhfNumericTextField from "@/components/Forms/Fields/RhfNumericTextField.tsx";
import {useFeeProposalsProvider} from "@/components/Providers/InstructorFeeProposalsProvider.tsx";
import {jwtInstructorContext} from "@/components/Providers/JWTProvider.tsx";
import useSaveFeeProposal from "@/hooks/useSaveFeeProposal.tsx";
import './FeeQuestionsForm.css';
import {FeeQuestion} from "@/types/FeeQuestion.ts";
import MuiDatePicker from "@/components/Forms/Fields/MuiDatePicker.tsx";
import SponsorList from "@/components/Forms/SponsorList.tsx";
import {FeeRequest} from "@/types/FeeRequest.ts";

export interface FeeProposalFormValues {
    feeRequestId?: string;
    name: string;
    school: string;
    year: string;
    category ?: string;
    approvalRoute: string;
    type: string;
    cost: number;
    accountNumber ?: string;
    accountAssignedByBusinessOffice ?: boolean;
    collectionStart: Date | null;
    collectionEnd: Date | null;
    description: string;
    otherInformation?: string;
    sponsors: Employee[];
    requiresAdditionalInformation: boolean;
    questions: FeeQuestion[];
}

const feeProposalRequestSchema = z.object({
    feeRequestId: z.string().uuid().optional(),
    name: z.string(),
    school: z.string(),
    year: z.string(),
    category: z.string().optional(),
    approvalRoute: z.string(),
    type: z.string(),
    cost: z.coerce.number({ required_error: "Required", invalid_type_error: "Required" }).min(1, 'Required'),
    accountNumber: z.string().optional(),
    accountAssignedByBusinessOffice: z.boolean().optional(),
    collectionStart: z.date().nullable().refine(
        (date) => date && !(date.getDate() < (new Date()).getDate()),
        {
        message: "Date must not be in the past.",
        }),
    collectionEnd: z.date().nullable().refine(
        (date) => date && !(date.getDate() < (new Date()).getDate()),
        {
            message: "Date must not be in the past.",
        }),
    description: z.string(),
    otherInformation: z.string().optional(),
    sponsors: z.array(z.object({
        id: z.string(),
        name: z.string(),
    })).min(1, 'You must have at least one sponsor.'),
    requiresAdditionalInformation: z.string().transform((value) => {
        return value === 'yes';
    }),
    questions: z.array(
        z.object({
            question: z.string().min(1, 'This field is required.'),
            responseType: z.string().min(1, "Response Type is required."),
            answerList: z.string(),
        })
        .refine(
        (data) => data.responseType !== 'Value List' || data.answerList !== '',
        {
            message: "'Value List Options' is required when 'Question Type' is 'Value List'",
            path: ['answerList'],
        })
    ),
}).superRefine((data, ctx) => {
    if (data.requiresAdditionalInformation && data.questions.length === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "There must be at least one question when Requires Additional Information is Yes.",
            path: ['requiresAdditionalInformation']
        });
    }

    if (data.type === 'Optional' && !data.category) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Category is required when Type is set Optional",
            path: ['category']
        });
    }

    if (data.collectionStart && data.collectionEnd &&
        (
            data.collectionStart > data.collectionEnd ||
            data.collectionEnd < data.collectionStart ||
            data.collectionStart === data.collectionEnd
        )
    ) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Collection start date must be before collection end date",
            path: ['collectionEnd']
        });
    }

    if (!data.accountAssignedByBusinessOffice && !data.accountNumber) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Account Number required if not assigned by BO",
            path: ['accountNumber']
        })
    }
});

export type FeeProposalRequestSchemaType = z.infer<typeof feeProposalRequestSchema>;

type Props = {
    show: boolean,
    setShow: Dispatch<SetStateAction<boolean>>,
    feeRequest?: FeeRequest | null
    setFeeRequest: Dispatch<SetStateAction<FeeRequest | null>>
}
const FeeProposalRequestForm = ({show, setShow, feeRequest = null, setFeeRequest} : Props) => {
    const {user} = useContext(jwtInstructorContext);
    const {
        loading,
        schools,
        schoolYears,
        approvalRoutes,
        employees,
        feeCategories,
        refresh
    } = useFeeProposalsProvider();
    const saveFeeProposal = useSaveFeeProposal();

    const currentEmployee = useMemo(() => {
        if (!employees) {
            return undefined;
        }
        return employees.find(emp => emp.id === user?.employeeID)
    }, [employees, user]);

    let defaultValues = {};
    if (feeRequest) {
        defaultValues = {
            feeRequestId: feeRequest.id,
            name: feeRequest.feeName,
            school: feeRequest.school.id.toString(),
            year:feeRequest.schoolYear.id,
            category: feeRequest.feeCategory ? feeRequest.feeCategory.id.toString() : '',
            approvalRoute: feeRequest.approvalRoute ? feeRequest.approvalRoute.id.toString() : '',
            type: feeRequest.feeType.charAt(0).toUpperCase() + feeRequest.feeType.slice(1),
            cost: feeRequest.cost,
            accountNumber: feeRequest.accountCode,
            accountAssignedByBusinessOffice: feeRequest.accountAssignedByBusinessOffice,
            description: feeRequest.feeDescription,
            otherInformation: feeRequest.otherInformation,
            requiresAdditionalInformation: 'no',
            questions: feeRequest.courseQuestions,
            sponsors: feeRequest.facultySponsors,
            collectionStart: new Date(feeRequest.saleStartDate.toLocalDateTime().toString()),
            collectionEnd: new Date(feeRequest.saleEndDate.toLocalDateTime().toString()),
        }
    } else {
        defaultValues = {
            sponsors: currentEmployee ? [{id: currentEmployee.id, name: currentEmployee.name}] : [],
            questions: [],
        }
    }

    const form = useForm<FeeProposalFormValues>({
        defaultValues: defaultValues,
        resolver: zodResolver(feeProposalRequestSchema),
    });

    const doSaveFeeProposal = async (data: FeeProposalRequestSchemaType) => {
        await saveFeeProposal(data);
        await refresh();
        setShow(false);
    };

    const watchType = form.watch("type", '');
    const watchAdditionalInfo = form.watch("requiresAdditionalInformation") as unknown as string;

    useEffect(() => {
        if (show) {
            const sponsors = form.getValues('sponsors');

            if (sponsors.length === 0 && currentEmployee) {
                form.setValue(
                    'sponsors',
                    [{id: currentEmployee.id, name: currentEmployee.name}]
                );
            }
        }
    }, [show]);

    useEffect(() => {
        form.reset(defaultValues);
    }, [feeRequest]);

    return <>
        {!show && <Button
            variant="outlined"
            onClick={() => {
                setFeeRequest(null);
                form.reset();
                setShow(true);
            }}
            sx={{
                p: 1,
            }}
        >
            <AddCircleOutlineIcon sx={{mr: 1}}/>
            Add Request
        </Button>}

        {show && loading && <CircularProgress/>}

        {!loading && <FormProvider {...form}>
            <Dialog
                open={show}
                onClose={() => {
                    setShow(false);
                    setFeeRequest(null);
                    form.reset();
                }}
                aria-labelledby="alert-dialog-name"
                aria-describedby="alert-dialog-description"
                fullWidth
                maxWidth={'md'}
            >
                <form onSubmit={
                    form.handleSubmit(async (data, event) => {
                        await doSaveFeeProposal(data);
                        event?.stopPropagation();
                    })
                }>
                    <DialogTitle id="alert-dialog-name">
                        Fee Request
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            sx={{
                                backgroundColor: '#eee',
                                borderRadius: '8px',
                                p: 1,
                            }}
                        >
                            <RhfTextField control={form.control} name={'feeRequestId'} sx={{display: 'none'}}/>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name={'name'}
                                    label={'Name'}
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name="school"
                                    label="School"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {schools && schools.map(school => <MenuItem
                                        key={`school-${school.id}`}
                                        value={`${school.id}`}
                                    >
                                        {school.shortName}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name='year'
                                    label='Year'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {schoolYears && schoolYears.map(schoolYear => <MenuItem
                                        key={`school-year-${schoolYear.id}`}
                                        value={`${schoolYear.id}`}
                                    >
                                        {schoolYear.year}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name='approvalRoute'
                                    label='Approval Route'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {approvalRoutes && approvalRoutes.map(approvalRoute => <MenuItem
                                        key={`approval-${approvalRoute.id}`}
                                        value={`${approvalRoute.id}`}
                                    >
                                        {approvalRoute.route}
                                    </MenuItem>)}
                                </RhfTextField>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name='type'
                                    label='Type'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <MenuItem key='type-course' value='Course' onClick={() => form.setValue('category', '')}>
                                        Course
                                    </MenuItem>
                                    <MenuItem key='type-enrollment' value='Enrollment' onClick={() => form.setValue('category', '')}>
                                        Enrollment
                                    </MenuItem>
                                    <MenuItem key='type-optional' value='Optional'>Optional</MenuItem>
                                </RhfTextField>
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                {watchType === 'Optional' && <RhfTextField
                                    select
                                    control={form.control}
                                    name='category'
                                    label='Category'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    {feeCategories && feeCategories.map(feeCategory => <MenuItem
                                        key={`approval-${feeCategory.id}`}
                                        value={`${feeCategory.id}`}
                                    >
                                        {feeCategory.name}
                                    </MenuItem>)}
                                </RhfTextField>}
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfNumericTextField
                                    control={form.control}
                                    name='cost'
                                    label='Cost'
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        endAdornment: <InputAdornment position="start">.00</InputAdornment>,
                                    }}
                                    inputProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <MuiDatePicker
                                    name='collectionStart'
                                    label='Collection Start'
                                    defaultValue={feeRequest?.saleStartDate}
                                    sx={{
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <MuiDatePicker
                                    name='collectionEnd'
                                    label='Collection End'
                                    defaultValue={feeRequest?.saleEndDate}
                                    sx={{
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name='accountNumber'
                                    label='Account Number'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} sx={{p: 1, display: 'flex'}}>
                                <FormControlLabel
                                    control={<RhfCheckbox
                                        control={form.control}
                                        name='accountAssignedByBusinessOffice'
                                    />}
                                    label="To be Assigned by Business Office"
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                        mx: 0,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name='description'
                                    label='Description'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p: 1}}>
                                <RhfTextField
                                    control={form.control}
                                    name='otherInformation'
                                    label='Other Information'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                    multiline
                                    minRows={3}
                                    maxRows={10}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{p: 1}}>
                                <SponsorList
                                    form={form}
                                    sponsors={employees ?? []}
                                    selectedSponsors={form.getValues('sponsors') ?? []}
                                />
                            </Grid>
                            <Grid item xs={12} md={8} sx={{p: 1}}>
                                <RhfTextField
                                    select
                                    control={form.control}
                                    name='requiresAdditionalInformation'
                                    label='Does the fee require information to be collected from the student?'
                                    sx={{
                                        width: '100%',
                                        backgroundColor: '#fff',
                                    }}
                                >
                                    <MenuItem key='requiresAdditionalInformation-yes' value='yes'>Yes</MenuItem>
                                    <MenuItem key='requiresAdditionalInformation-no' value='no' onClick={() => form.setValue('questions', [])}>No</MenuItem>
                                </RhfTextField>
                            </Grid>
                            <Grid item xs={12} sx={{p: 1}}>
                                {watchAdditionalInfo === 'yes' && <FeeQuestionsForm form={form}/>}
                            </Grid>
                        </Grid>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            type={'submit'}
                            sx={{
                                backgroundColor: '#631d79',
                                color: '#fff',
                                p: 1,
                                borderRadius: '8px',
                                fontWeight: 800,
                                mb: 2,
                                mr: 2,
                                '&:hover': {
                                    backgroundColor: '#631d79',
                                }
                            }}
                        >
                            Submit Proposal
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </FormProvider>}
    </>
};

export default FeeProposalRequestForm;
