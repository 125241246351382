import {AlertCircle, BookOpen, Lock} from 'lucide-react';
import * as React from 'react';
import {MutableRefObject, useContext, useMemo, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    FormControl,
    MenuItem,
    Select,
    Stack,
    Tooltip,
    Typography,
    useMediaQuery, useTheme
} from "@mui/material";
import {Fee} from "@/types/Fee.ts";
import ViewMoreModalLink from "@/components/Fees/ViewMoreModalLink.tsx";
import {apiEndpoint, jwtContext} from "@/components/Providers/JWTProvider.tsx";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import {getFeeDisplayIconType} from "@/Helpers/getFeeDisplayIconType.ts";
import {buildFeePrice} from "@/Helpers/buildFeePrice.ts";
import {formatter} from "@/Helpers/formatter.ts";
import FeeQuestionModal from "@/components/Modal/FeeQuestionModal.tsx";
import {AssessedFee} from "@/types/AssessedFee.ts";

type Props = {
    fee: Fee;
    alternateBackground?: boolean;
    assessedFee?: AssessedFee;
    cartRef?: MutableRefObject<HTMLSpanElement | null>
};

const OptionalFeeListItem = ({
    fee, alternateBackground, assessedFee, cartRef
} : Props) : React.ReactElement => {
    const [quantity, setQuantity] = useState<number>(1);
    const user = useContext(jwtContext)
    const {cart, refreshCart} = useCartContext();
    const {refreshFees, purchaseHistory} = useFeeProvider();
    const [showCourseQuestionModal, setShowCourseQuestionModal] = useState<boolean>(false);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('md'));

    const isInCart = cart?.cartItems
        .some(item => item.itemType === 'fee' && item.feeId === fee.id) ?? false;

    const displayIcon = getFeeDisplayIconType(isInCart, fee, user, purchaseHistory, assessedFee);

    const currentPrice = useMemo(() => {
        return formatter.format(buildFeePrice(fee, user, purchaseHistory))
    }, [fee, user, purchaseHistory])

    const remainingQuantity = useMemo(() => {
        const timesPreviouslyPurchased = purchaseHistory?.fees[fee.id] ?? 0;
        return fee.quantity - timesPreviouslyPurchased;
    }, [fee, purchaseHistory]);

    const handleAddToCart = async () => {
        const url = new URL('/v1/fees/add-to-cart', apiEndpoint);

        let init : RequestInit = {
            method: 'PUT',
            signal: AbortSignal.timeout(10000)
        };

        init.body = JSON.stringify({
            feeId: fee.id,
            quantity,
        });

        const response = await user?.apiFetch(url.toString(), init);

        if (response && response.ok) {
            await refreshFees();
            await refreshCart();
            if (mdDown && cartRef && cartRef.current !== null) {
                cartRef.current.scrollIntoView();
            }
        } else {
            // setErrorMessage('An unknown error has occurred.');
            console.info('An unknown error has occurred.');
        }
    }

    const handleFeeQuestionsModal = async () => {
        if (fee.courseQuestions.length) {
            setShowCourseQuestionModal(true);
            return;
        }

        await handleAddToCart();
    }

    return <>
        {user && <Card
            variant="outlined"
            sx={{
                boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                transition: 'box-shadow 0.3s ease-in-out',
                '&:hover': {
                    boxShadow: '0 4px 8px rgba(0,0,0,0.12)'
                },
                border: '1px solid rgba(0,0,0,0.12)',
                bgcolor: alternateBackground ? 'background.alternate' : 'background.paper',
                mb: 2,
                mr: 2,
            }}
        >
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: {xs: 'column', md: 'row'},}}>
              <Box sx={{ display: 'flex', gap: 2, flex: 1, mr: 4 }}>
                <Box sx={{ color: '#6B21A8' }}><BookOpen /></Box>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" gutterBottom>{fee.name}</Typography>
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                      {fee.description.substring(0, 120).split('¶').map((line) => <Box style={{display: 'inherit'}}>{line}</Box>)}
                      {fee.description.length > 120 && (
                          <ViewMoreModalLink fee={fee}/>
                      )}
                  </Typography>
                    {!fee.allowPartialPayment && <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: 'primary.main',
                        fontSize: '0.875rem',
                        mb: 1
                    }}>
                        <Lock size={16} />
                        <Typography variant="body2" sx={{ fontWeight: 500 }}>
                            Requires Payment in Full
                        </Typography>
                    </Box>}
                  <Stack direction="row" spacing={1}>
                    <Chip
                        label={fee.feeCategory}
                        size="small"
                        color={'secondary'}
                        sx={{ height: '24px' }}
                    />
                    <Chip
                        label={'Optional'}
                        size="small"
                        color="default"
                        variant="outlined"
                        sx={{ height: '24px' }}
                    />
                  </Stack>
                </Box>
              </Box>
              <Box sx={{ minWidth: 160, textAlign: 'right', width: {xs: '100%', md: 'auto'}}}>
                <Typography variant="h6" gutterBottom>
                  {currentPrice}
                </Typography>
                  {displayIcon === 'BlockIcon' && (
                    <Tooltip title="Fee has been added the maximum allowable times" arrow>
                        <AlertCircle size={24} color="#6B21A8" />
                    </Tooltip>
                  )}
                  {['CartIcon', 'InCart'].includes(displayIcon) && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'flex-end' }}>
                          <>
                              <FormControl fullWidth size="small">
                                  <Select
                                      value={quantity}
                                      onChange={(e) => setQuantity(Number(e.target.value))}
                                      disabled={isInCart}
                                      sx={{
                                          height: '32px',
                                          '.MuiSelect-select': {
                                              py: '4px'
                                          }
                                      }}
                                  >
                                      {[...Array(remainingQuantity > 20 ? 20 : remainingQuantity )].map((_, i) => (
                                          <MenuItem key={i + 1} value={i + 1}>
                                              Qty: {i + 1}
                                          </MenuItem>
                                      ))}
                                  </Select>
                              </FormControl>
                              <Button
                                  variant="contained"
                                  color="secondary"
                                  disabled={isInCart}
                                  onClick={() => handleFeeQuestionsModal()}
                                  size="small"
                                  fullWidth
                              >
                                  {isInCart ? 'Added' : 'Add to Cart'}
                              </Button>
                          </>
                      </Box>
                  )}
              </Box>
            </Box>
          </CardContent>
        </Card>}
        {fee.courseQuestions && <FeeQuestionModal
            show={showCourseQuestionModal}
            handleClose={() => setShowCourseQuestionModal(false)}
            fee={fee}
            performActionBasedOnCourseStatus={handleAddToCart ?? (async () => {})}
        />}
    </>;
}

export default OptionalFeeListItem;
