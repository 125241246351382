import * as React from 'react';
import {useMemo} from 'react';
import {Box, IconButton, Stack} from "@mui/material";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import CartSummaryRegistrationListItem from "@/components/Cart/CartSummaryRegistrationListItem.tsx";
import {CartItem} from "@/types/CartItem.ts";
import {X} from "lucide-react";

type NormalizedCartItem = {
    cartItem: CartItem;
    quantity: number;
};

const CartSummaryList = () : React.ReactElement => {
    const {cart, deleteCartItem} = useCartContext();

    const normalizedCart = useMemo(() : NormalizedCartItem[] => {
        const nc = new Map<string, NormalizedCartItem>();
        if (!cart || !cart.cartItems) {
            return Array.from(nc.values());
        }

        for (const cartItem of cart.cartItems) {
            const ciKey = `${cartItem.courseSectionId ? cartItem.courseSectionId : cartItem.feeId}-${cartItem.cost}`;
            const ci = nc.get(ciKey);
            if (!ci) {
                nc.set(ciKey, {
                    cartItem,
                    quantity: 1,
                });
            } else {
                nc.set(
                    ciKey,
                    {
                        cartItem,
                        quantity: (ci.quantity ?? 0) + 1,
                    }
                )
            }
        }

        return Array.from(nc.values());
    }, [cart])

    return <Box
        sx={{
            flexGrow: 1,
            overflowY: {xs: 'visible', md: 'auto'},
        }}
    >
        {normalizedCart.map(normalizedCartItem => <Box
            key={`payment-due-${normalizedCartItem.cartItem.id}`}
            sx={{
            }}
        >
            <Stack sx={{flexDirection: 'row', py: 1, pr: 2}}>
                <CartSummaryRegistrationListItem cartItem={normalizedCartItem.cartItem} quantity={normalizedCartItem.quantity}/>
                <Box>
                    <IconButton
                        edge="end"
                        onClick={() => {
                            let ciKey = normalizedCartItem.cartItem.courseSectionId ?? normalizedCartItem.cartItem.feeId;
                            const cartItems = cart?.cartItems
                                .filter(ci => (ci.courseSectionId === ciKey || ci.feeId === ciKey) && ci.cost === normalizedCartItem.cartItem.cost)
                                .map(ci => ci.id) ?? [];
                            deleteCartItem(cartItems);
                        }}
                        color="error"
                    >
                        <X/>
                    </IconButton>
                </Box>
            </Stack>
        </Box>)}
    </Box>;
}

export default CartSummaryList;
