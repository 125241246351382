import * as React from 'react';
import FormControl from '@mui/material/FormControl';
import {InputAdornment, TextField} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";

type Props = {
    searchFeesValue: string;
    setSearchFeesValue: (searchFeesValue: string) => void;
}
const SearchFees = ({searchFeesValue, setSearchFeesValue} : Props) : React.ReactElement => {

    return (
        <FormControl
            sx={{
                width: '100%',
            }}
        >
            <TextField
                label="Search Fees"
                variant="outlined"
                name='searchFees'
                value={searchFeesValue}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchFeesValue(event.target.value);
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchOutlined/></InputAdornment>,
                }}
            />
        </FormControl>
    );
}

export default SearchFees;
