import NavHeader from "../components/Navigation/NavHeader";
import {type ReactElement, useRef, useState} from "react";
import {Box, Card, Stack, Typography} from "@mui/material";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";
import SystemAlert from "@/components/SystemAlert.tsx";
import TotalRow from "@/components/Cart/TotalRow.tsx";
import MainDisplayContainer from "@/components/Layout/MainDisplayContainer.tsx";
import AssessedFeeList from "@/components/Fees/AssessedFeeList.tsx";
import OptionalFeeList from "@/components/Fees/OptionalFeeList.tsx";
import FeeToggle from "@/components/Fees/FeeToggle.tsx";
import CartSummaryList from "@/components/Cart/CartSummaryList.tsx";
import {useFeeProvider} from "@/components/Providers/FeesProvider.tsx";
import PurchasedFeesList from "@/components/Fees/PurchasedFeesList.tsx";

const FeePage = () : ReactElement => {
    const {getCartAlertStatus, resetCartAlertStatus, getCartTotal, cartIsEmpty} = useCartContext();
    const {optionalFees, assessedFees, purchasedFees} = useFeeProvider();
    const totalCost = getCartTotal();
    const cartStatus = getCartAlertStatus();
    const [errorMessage] = useState('');
    const [feeToggleValue, setFeeToggleValue] = useState<number>(1);
    const cartRef = useRef<null | HTMLSpanElement>(null);

    return <Stack sx={{backgroundColor: '#631d79', flexDirection: 'column', height: '100vh'}}>
        <NavHeader showNav={true}/>
        <MainDisplayContainer>
            <Box
                sx={{
                    height: '100%',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: {xs: 'column', md: 'row'},
                    overflowY: {xs: 'auto', md: 'hidden'}
                }}
            >
                <Stack
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        width: {xs: '100%', md: '60%'},
                        px: 2,
                        pt: 2,
                        mb: 2,
                        backgroundColor: '#fff',
                        borderRadius: 1,
                    }}
                >
                    <Stack
                        sx={{
                            flexDirection: 'column',
                            flexGrow: 1,
                            // height: '100%',
                            overflowY:  'hidden',
                        }}
                    >
                        <Box>
                            <FeeToggle feeToggleValue={feeToggleValue} setFeeToggleValue={setFeeToggleValue}/>
                        </Box>
                        <Stack
                            sx={{
                                flexDirection: 'column',
                                flexGrow: 1,
                                overflowY:  'auto',
                            }}
                        >
                            {feeToggleValue === 0 && assessedFees && <AssessedFeeList cartRef={cartRef} purchasedFees={assessedFees}/>}
                            {feeToggleValue === 1 && optionalFees && <OptionalFeeList cartRef={cartRef} optionalFees={optionalFees}/>}
                            {feeToggleValue === 2 && assessedFees && <PurchasedFeesList purchasedFees={purchasedFees}/>}
                        </Stack>
                    </Stack>
                </Stack>
                <Box sx={{
                    width: {xs: '100%', md: '40%'},
                    pl: {md: 2},
                }}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            px: 2,
                            pt: 2,
                        }}
                    >
                        <Typography
                            variant={'h5'}
                            sx={{
                                py: 1,
                                color: '#000',
                            }}
                        >
                            <span ref={cartRef}>Cart Summary</span>
                        </Typography>
                        <CartSummaryList/>
                        <TotalRow
                            totalCost={totalCost}
                            cartIsEmpty={cartIsEmpty}
                            errorMessage={errorMessage}
                        />
                    </Card>
                </Box>
            </Box>
            <SystemAlert
                show={cartStatus.showAlert}
                alertText={cartStatus.message}
                clearSystemAlert={resetCartAlertStatus}
                variant={cartStatus.alertVariant}
                isFixed={true}
            />
        </MainDisplayContainer>
    </Stack>
};

export default FeePage;
