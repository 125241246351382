import * as React from 'react';
import {Box, Tab, Tabs} from '@mui/material';

type Props = {
    feeToggleValue: number;
    setFeeToggleValue: (feeToggleValue: number) => void;
};

const FeeToggle = ({feeToggleValue, setFeeToggleValue} : Props) : React.ReactElement => {
    const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        setFeeToggleValue(newValue);
    };

    return (
        <Box sx={{borderBottom: 1, borderColor: 'divider', mb: 1}}>
            <Tabs value={feeToggleValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="Assessed Fees"/>
                <Tab label="Optional Fees"/>
                <Tab label="Purchased Fees"/>
            </Tabs>
        </Box>
    );
}

export default FeeToggle;
