import * as React from 'react';
import {useMemo} from 'react';
import {AssessedFee} from "@/types/AssessedFee.ts";
import {Stack} from "@mui/material";
import OptionalFeeListItem from "@/components/Fees/OptionalFeeListItem.tsx";

type Props = {
    purchasedFees: AssessedFee[];
};

const PurchasedFeesList = ({purchasedFees} : Props) : React.ReactElement => {

    const orderedPurchasedFees = useMemo(() => {
        return purchasedFees?.sort((a, b) => {
            const cat = a.fee.feeCategory.localeCompare(b.fee.feeCategory)
            if (cat !== 0) {
                return cat;
            }

            return a.fee.name.localeCompare(b.fee.name);
        })
    }, [purchasedFees]);


    return <Stack>
        {orderedPurchasedFees?.map((studentFee) => {
            return <OptionalFeeListItem
                key={`student-fee-${studentFee.id}`}
                fee={studentFee.fee}
                assessedFee={studentFee}
            />
        })}
    </Stack>
}

export default PurchasedFeesList;
