import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import SignIn from "../../pages/SignIn";
import InstructorSignIn from "../../pages/instructor/InstructorSignIn";
import {decodeJwt} from "jose";
import {USER_TYPE} from "@/types/UserType.ts";

type Props = {
    children ?: ReactNode;
};

export type User = {
    accessToken : string;
    apiFetch : ApiFetch;
    expires : number;
}

export type JWTUser = User & {
    nameFirst : string;
    nameLast : string;
    financialAid : 'none' | 'free' | 'reduced';
    userType : USER_TYPE;
    studentId : string;
};

export type JWTInstructor = User &  {
    username: string;
    employeeID: string;
    role : string[];
}

export const INSTRUCTOR_TOKEN = 'JWTInstructor';
export const USER_TOKEN = 'JWTUser';

export const jwtContext = createContext<JWTUser | null>(null);
export const jwtInstructorContext = createContext<{
    user: JWTInstructor | null
    setUser : (user : JWTInstructor | null) => void
}>({
    user : null,
    setUser: (_user : JWTInstructor| null) => {alert('there was a application error.')},
});

// This probably could / should be made into something more generic, but it's just tightly
// coupled enough right now to use this in-between design for now
const JWTProvider : React.FC<Props> = ({children} : Props) => {
    const [user, setUser] = useState<JWTUser | null>(null);

    useEffect(() => {
        try {
            const localUser: JWTUser = JSON.parse(localStorage.getItem(USER_TOKEN) ?? '');
            if (localUser && localUser.accessToken) {
                localUser.apiFetch = authenticatedApiFetchGeneric(localUser.accessToken);
                setUser(localUser);
            } else {
                setUser(null);
            }
        } catch (e) {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        const triggerTimeout = (user.expires - (Date.now() / 1000) - 59) * 1000;
        if (triggerTimeout < 0) {
            return;
        }

        const timer = setTimeout(async () => {
            if (!user?.apiFetch) {
                return;
            }
            await refreshToken(user, setUser, USER_TOKEN);
        }, triggerTimeout)
        return () => {
            timer !== null && clearTimeout(timer);
        }
    }, [user]);

    if (!user || user.expires - (Date.now()/1000) - 60 < 0) {
        return (<SignIn setUser={setUser} />);
    }

    return (
        <jwtContext.Provider value={user}>
            {children}
        </jwtContext.Provider>
    );
};

async function refreshToken<T extends User>(
    user: T,
    setUser: (user : T | null) => void,
    storageKey : (typeof  INSTRUCTOR_TOKEN | typeof USER_TOKEN)
) {
    const url = new URL(`/v1/identity/refresh-token`, apiEndpoint);
    try {
        const refresh = await user.apiFetch(url.toString(), {method: 'POST'});

        if (!refresh.ok) {
            console.error('error refreshing token');
            setUser(null);//send the user to the login screen
        }

        const newToken = await refresh.json();

        if (!newToken.accessToken) {
            console.error('error refreshing token', newToken);
            setUser(null);//send the user to the login screen
        }

        const claims = decodeJwt(newToken.accessToken);
        const newUser = {
            ...user,
            accessToken: newToken.accessToken,
            apiFetch: authenticatedApiFetchGeneric(newToken.accessToken),
            expires: claims.exp,
        } as T;
        localStorage.setItem(storageKey, JSON.stringify(user));

        setUser(newUser);
    } catch (e) {
        console.error('error refreshing token', e);
    }
}

export const JWTInstructorProvider : React.FC<Props> = ({children} : Props) => {
    const [user, setUser] = useState<JWTInstructor | null>(null);

    useEffect(() => {
        try {
            const localUser: JWTInstructor = JSON.parse(localStorage.getItem(INSTRUCTOR_TOKEN)!);
            if (localUser && localUser.accessToken) {
                localUser.apiFetch = authenticatedApiFetchGeneric(localUser.accessToken);
                const jwt = decodeJwt(localUser.accessToken) as { sub: string, role: [], iat: number, exp: number };
                localUser.role = Array.isArray(jwt.role) ? jwt.role : [];
                setUser(localUser);
            } else {
                setUser(null);
            }
        } catch (e) {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        if (!user) {
            return;
        }
        const triggerTimeout = (user.expires - (Date.now() / 1000) - 59) * 1000;
        if (triggerTimeout < 0) {
            return;
        }

        const timer = setTimeout(async () => {
            if (!user?.apiFetch) {
                return;
            }
            await refreshToken(user, setUser, INSTRUCTOR_TOKEN);
        }, triggerTimeout)
        return () => {
            timer !== null && clearTimeout(timer);
        }
    }, [user]);

    // if their token is only good for a minute lets send them to the login screen as it could have already expired due to clock drift
    if (!user || user.expires - (Date.now()/1000) - 60 < 0) {
        return <jwtInstructorContext.Provider value={{
            user,
            setUser
        }}>
            <InstructorSignIn />
        </jwtInstructorContext.Provider>
    }

    return (
        <jwtInstructorContext.Provider value={{
            user,
            setUser
        }}>
            {children}
        </jwtInstructorContext.Provider>
    );
};

export const isStudentId = (studentId: string) => {
    return !isNaN(Number(studentId));
}


export const apiEndpoint = import.meta.env.VITE_APP_API_ENDPOINT;
export type ApiFetch = (url : string, init ?: RequestInit) => Promise<Response>;

type AuthenticateUser = (studentId : number, birthDate : string) => Promise<JWTUser | null>;
type AuthenticateInstructor = (username: string, password: string) => Promise<JWTInstructor | null>;


export const useAuthenticateUser = () : AuthenticateUser => {
    return useCallback(async (studentId : number, dob : string) : Promise<JWTUser | null> => {
        let init : RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'POST'};
        const url = new URL(`/v1/student`, apiEndpoint);
        init.body = JSON.stringify({
            studentId: studentId,
            dob: dob
        });
        const response = await fetch(url.toString(), init);

        if (response.status !== 200) {
            return null;
        }

        const user = await response.json();
        user.studentId = studentId
        const claims = decodeJwt(user.accessToken);
        user.expires = claims.exp;
        localStorage.setItem(USER_TOKEN, JSON.stringify(user));
        user.apiFetch = authenticatedApiFetchGeneric(user.accessToken);

        return user;
    }, []);
};
export const useAuthenticateInstructor = () : AuthenticateInstructor => {
    return useCallback(async (username : string, password : string) : Promise<JWTInstructor | null> => {
        let init : RequestInit = {headers: new Headers({'Content-Type': 'application/json'}), method: 'POST'};
        const url = new URL(`/v1/instructor`, apiEndpoint);
        init.body = JSON.stringify({
            username: username,
            password: password
        });
        const response = await fetch(url.toString(), init);
        if (response.status !== 200) {
            return null;
        }

        const user = await response.json();
        const claims = decodeJwt(user.accessToken);
        user.expires = claims.exp;
        user.role = claims.role;
        localStorage.setItem(INSTRUCTOR_TOKEN, JSON.stringify(user));
        user.apiFetch = authenticatedApiFetchGeneric(user.accessToken);

        return user;
    }, []);
};

const signOutGeneric  = (key:string) => {
    return localStorage.removeItem(key);
};

export const signOut = () => signOutGeneric(USER_TOKEN)
export const signOutInstructor = () => signOutGeneric(INSTRUCTOR_TOKEN)

export const authenticatedApiFetchGeneric = (token:string) : ApiFetch => {

    return async (url : string, init ?: RequestInit) : Promise<Response> => {
        if (!init || !token) {
            init = {};
        }

        init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
        init.headers.set('Authorization', `Bearer ${token}`);
        init.headers.set('Content-Type', 'application/json');

        const response = await fetch(url, init);

        if (response.status === 401) {
            if (response.status === 401) {
                signOut();
                window.location.href = '/';
            }
        }

        return response;
    };
};
export const authenticatedApiFetch = () : ApiFetch => {
    const localUser : JWTUser = JSON.parse(localStorage.getItem(USER_TOKEN)!);
    return authenticatedApiFetchGeneric(localUser?.accessToken);
}

export default JWTProvider;
