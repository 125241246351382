import * as React from 'react';
import {Box, Stack, Tooltip, Typography} from "@mui/material";
import {formatter} from "@/Helpers/formatter.ts";
import moment from "moment/moment";
import {CartItem} from "@/types/CartItem.ts";
import {Lock} from 'lucide-react';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {useCartContext} from "@/components/Providers/CartProvider.tsx";

type Props = {
    cartItem: CartItem;
    quantity: number;
};

const CartSummaryRegistrationListItem = ({
    cartItem,
    quantity,
} : Props) : React.ReactElement => {
    const {cart, deleteCartItem} = useCartContext();
    if (cartItem.itemType === 'fee') {
        return <Box sx={{flexGrow: 1,}}>
            <Stack spacing={1} width="100%">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!cartItem.allowPartialPayment && (
                        <Tooltip title="Requires Payment in Full" arrow>
                            <Lock size={16} color="#6B21A8" />
                        </Tooltip>
                    )}
                    <Typography variant="body1">{cartItem.name}</Typography>
                </Box>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Select
                        value={quantity}
                        size="small"
                        onChange={(e) => {
                            const newCount = typeof e.target.value === "string"
                                ? Number.parseInt(e.target.value) : e.target.value;

                            const ciKey = cartItem.courseSectionId ?? cartItem.feeId;
                            const cartItems = cart?.cartItems
                                .filter(ci => ci.courseSectionId === ciKey || ci.feeId === ciKey)
                                .map(ci => ci.id) ?? [];
                            deleteCartItem(cartItems.slice(0, quantity - newCount));
                        }}
                        sx={{
                            minWidth: 80,
                            height: '32px',
                            '.MuiSelect-select': {
                                py: '4px'
                            }
                        }}
                    >
                        {[...Array(quantity)].map((_, i)=> {
                            return <MenuItem key={`change-quantity-${i}`} value={i+1}>
                                {i+1}
                            </MenuItem>
                        })}
                    </Select>
                    <Typography variant="body2" color="text.secondary">
                        {formatter.format(cartItem.cost)}
                    </Typography>
                </Stack>
            </Stack>


        </Box>;
    }

    return <Box sx={{flexGrow: 1,}}>
        <Box sx={{display: 'inline', pr: 1, color: '#000', fontWeight: 800}}>{cartItem.name}</Box>
        {cartItem.deliveryMethod &&
            <Box sx={{display: 'inline', pr: 1, color: '#000'}}>{cartItem.deliveryMethod && ','}</Box>}
        <Box sx={{display: 'inline', pr: 1, fontWeight: 800, color: '#000'}}>Section {cartItem.sectionNumber}</Box>

        <Box sx={{px: 1}}>{moment(cartItem.dateStartFull).format('MM/DD')} - {moment(cartItem.dateEndFull).format('MM/DD')},</Box>
        <Box sx={{px: 1, display: 'inline'}}>{cartItem.meetingTime}</Box>

        <Box sx={{px: 1, color: '#000', fontWeight: 800}}>{formatter.format(cartItem.cost)}</Box>
    </Box>;
}

export default CartSummaryRegistrationListItem;
